.navbar {
  display: flex;
  background: $white;
  height: 120px;
  flex: 1;
  padding: 2rem 0;
  z-index: 1;

  &-title {
    flex: 1 1 0;
    padding: 0 1rem;

    h1 {
      font-size: 1.2rem;
      font-weight: 600;
      color: $gray-3;
    }

    p {
      color: $gray-1;
      font-size: 0.875rem;
    }
  }

  &-search {
    margin: 0 4rem 0 0;
    flex: 1 1 auto;
  }

  &-buttons {
    display: flex;
  }

  &-chat {
    $size: 3rem;
    height: $size;
    width: $size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: #100933;
    margin-right: 1rem;
    cursor: pointer;
    color: white;
  }

  &-profile {
    color: $gray-2;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: $border-radius;

      &:hover {
        background: #F0F0F0;
      }
    }

    &-name {
      margin-left: 1rem;
      font-weight: 600;
    }
  }
}

.navbar-label{
  background-color: #76BDFF;
  padding: 10px 10px;
  margin-right: 10px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  border-radius: 3px;
  h2{
    font-size: 1rem;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
  color: white;
}