.login {
  &-container {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &-form {
    margin: auto;
    padding: 2rem 1.5rem;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  &-message {
    font-weight: 600;
    margin-bottom: 4rem;

    .brand {
      color: $dark-purple;
    }
  }
}

.MuiInputBase {
  &-container {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem 0;
  }

  &-label {
    @extend .body-1;
    color: $gray-2;
  }

  &-feedback {
    display: flex;
    align-items: center;
    color: $error;
    font-size: 0.875rem;
    margin-top: 0.5rem;

    svg {
      margin-right: 0.25rem;
      width: 1rem;
      height: 1rem;
    }
  }
}

.MuiAutocomplete {
  &-container {
    @extend .MuiInputBase-container
  }
}

.button-primary {
  @extend .button;
  padding: 0.75rem 1.25rem;
  background-color: $turquoise;
  color: $athens-gray;
  border-radius: 100px;
}

.custom-input-fast{
  border: none;
  border-radius: 6px;
  width: 90%;
  padding: 10px 15px;
  &:focus{
    outline-color: $primary ;
  }
}

.org-input {

  input {
    border: none;
    border-radius: 6px;
    width: 100%;
    padding: 10px 15px;

    &:focus{
      outline-color: $primary ;
    }

  }

  margin-bottom: 1rem;

}

@include media-breakpoint-up(md) {
  .login {
    &-form {
      padding: 2rem;
    }
  }
}