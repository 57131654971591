
.main {
  flex: 1;
  transition: all 0.5s;
  margin: 0 1rem;
}

.sidebar {
  $width: 64px;
  min-width: $width;

  transition: all 0.5s;

  &-container {
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 64px;
    transition: width 0.5s;
    background: $sidebar-background;
    //box-shadow: $box-shadow;
  }

  +

  &-logo {
    display: flex;
    justify-content: center;
    margin: 24px auto;

    &-icon {
      display: flex;
      align-items: center;
      min-width: 32px;
    }

    &-brand {
      display: flex;
      align-items: center;
      width: 0;
      transition: width 0.5s;
    }
  }

  &-divider {
    background: none;
    border: 1px solid rgba(239, 145, 195, 0.5);
    margin: 0 0.5rem 1rem 0.5rem;
    transition: all 0.5s;
  }

  &-item {
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    font-weight: 500;
    color: $gray-3;
    margin: 1rem 0;
    transition: padding-left 0.5s;


    &.active {
      color: $primary;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        height: 48px;
        width: 8px;
        background: $primary-alt;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    &-label {
      overflow: hidden;
      width: 0;
      transition: width 0.5s;
    }

    &-icon {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

  }

  &-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &-logout {
    .MuiDrawer-item {
      cursor: pointer;
      color: $error;
    }
  }

  &.active {
    $width: 240px;
    min-width: $width;
    width: $width;

    .sidebar-container {
      width: $width;
    }

    .sidebar {

      &-logo {
        &-brand {
          width: 100%;
        }
      }

      &-divider {
        margin: 0 32px;
      }

      &-item {
        padding-left: 40px;

        &-label {
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .main {
    margin: 0 2rem;
  }
}

@include media-breakpoint-up(xl) {
  .main {
    margin: 0 3rem;
  }
}
