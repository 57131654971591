* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body, html {
  width: 100%;
  height: 100%;
  background-color: $background;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}

h1, h1, h3, h4, h5, h6, p, a {
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

h1, .h1 {
  font-weight: 300;
  font-size: 97px;
  letter-spacing: -1.5px;
}

h2, .h2 {
  font-weight: 300;
  font-size: 61px;
  letter-spacing: 0.5px;
}

h3, .h3 {
  font-weight: 400;
  font-size: 48px;
}

h4, .h4 {
  font-weight: 400;
  font-size: 34px;
}

h5, .h5 {
  font-weight: 400;
  font-size: 24px;
}

h6, .h6 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
}

.subtitle-1 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
}

.subtitle-2 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.body-1 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.body-2 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.button {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.25px;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.overline {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
}

a {
  text-decoration: none;
}

i {
  font-size: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 86400s ease-in-out 0s;
}

.cp {
  cursor: pointer;
}


.table-container {
  border-radius: 15px;
  padding: 2rem 0;
  background: white;
  box-shadow: 0 0 20px rgba(170, 170, 170, 0.15);
}

.title-container {
  h1 {
    font-size: 1.2rem;
    font-weight: 600;
    color: $gray-3;
  }

  p {
    color: $gray-1;
    font-size: 0.875rem;
  }
}

//hr {
//  color: #F4C5DE;
//  opacity: 1;
//}

.input-search {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  //background: red;
  border-radius: 500rem;
  border: 1px solid $gray-1;
  padding-left: 10px;

  svg {
    margin: auto;
    fill: $gray-1;
  }

  &:focus {
    outline: red;
  }

  input {
    border: none;
    width: 100%;
    background: none;
    border-radius: 500rem;
    padding: 5px 5px;

    &:focus {
      outline: none;
    }
  }

}

.warning-circle {
  content: '';
  background: #dadada;
  width: 35px;
  height: 25px;
  margin-right: 10px;
  border-radius: 6px;
}

.selected-circle {
  content: '';
  background: #010136;
  width: 35px;
  height: 25px;
  margin-right: 10px;
  border-radius: 6px;
}

.request-cell {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #c0a7ff;
}

.warning-cell {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #DADADA;
}


.sold-out-cell {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #16ff79;
}

.advise-card {
  border-radius: 12px;
  background: white;
  padding: 24px;
  box-shadow: 0 0 20px rgba(170, 170, 170, 0.15);

  span {
    color: #00B0FF;
  }

  h1 {
    font-size: 2.125rem;
    font-weight: 600;
    margin-bottom: 24px;
  }

}

.btn-rounded {
  border-radius: 50rem;
  background: #00B0FF;
  padding: 10px 24px;
  position: relative;

  span {
    color: white;
    font-weight: 300;
  }

  &.disabled {
    cursor: none;
    pointer-events: none;
    position: relative;
    background: #6c6b6b;

    span {
      color: #e1e1e1;
    }

  }
}


.btn-rounded-min {

  span {
    border-radius: 50rem;
    background: red;
    padding: 5px 5px;
    color: white;
    font-weight: 300;
    font-size: 0.8125rem !important;
  }
}

.Dashboard {
  &-wrapper {
    margin: 1rem 0;
  }
}

.title-pill {
  color: #828282;
  font-size: 1.25rem;
  font-weight: 500;
}

.gradient-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 110px;
  border-radius: 12px;
  color: white;
  background-image: linear-gradient(to right, #CEAFFF, #5F6FFF);
  transition: 0.25s;
  cursor: pointer;

  &.alternative {
    background-image: linear-gradient(to right, #FFAFC7, #FF5FB5);

  }

  svg {
    width: 200px;
    height: 150px;
    object-fit: cover;
    object-position: center;
  }

  .button-text {
    padding: 0 20px;
  }
}


.chat {
  @extend .bg-white-sh;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  padding: 1rem;
  height: 100%;
  border-radius: 12px;

  .chat-container {
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse !important;
    //flex-direction: column;

    .infinite-scroll-component{
      display: flex;
      flex-direction: column-reverse !important;
    }

    .timestamp {
      display: flex;
      margin: auto 10px;
      font-weight: 300;
      color: $gray-2;
    }

    .chat-sender {
      display: flex;
      justify-content: flex-start;
      flex-direction: row-reverse;
      margin-bottom: 10px;

      &:last-child {
        //margin-bottom: 0;
      }

      .message {
        background: #4E426D;
        word-break: break-word;
        word-wrap: break-word;
        max-width: 400px;
        hyphens: auto;
        color: white;
        padding: 0.95rem;
        border-radius: 15px 0 15px 15px;
        margin-right: 10px;
        font-weight: 300;
      }

    }

    .chat-receptor {
      @extend .chat-sender;
      flex-direction: row;

      .message {
        background: #666666;
        margin-right: 0;
        margin-left: 10px;
        border-radius: 0 15px 15px 15px;
      }
    }

  }

  .message-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.95rem;

    span {
      max-height: 100px;
      width: 100%;
      overflow-y: scroll;
      background: #EEEEEE;
      border-radius: 12px;
      padding: 12px;
      border: none;

      word-break: break-word;
      word-wrap: break-word;
      hyphens: auto;
      &:focus {
        outline: none;
      }

      &:empty::before {
        content: 'Mensaje...';
        opacity: 0.5;
      }
    }

    .send-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 65px;
      width: 65px;
      background: #6BE49C;
      color: white;
      padding: 10px;
      border-radius: 100%;
      margin: auto 0 0 20px;
    }
  }
}

.bg-white-sh {
  background-color: white;
  box-shadow: 0 0 10px rgba(170, 170, 170, 0.16);
}

.b-r-12 {
  border-radius: 12px;
}

.loader-float{
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 50%;
}

.label-size-small{


}
.primary-button {
  @extend .button;
  padding: 1rem 1.5rem;
  border-radius: $border-radius;
  background: $turquoise;
  color: $athens-gray;
}

.btn-gradient {
  display: flex;
  border-radius: 12px;
  padding: 25px;
  color: white;
  cursor: pointer;
  transition: 0.25s;
  position: relative;
  overflow: hidden;

  &.disabled {
    cursor: none;
    pointer-events: none;
    position: relative;

    &::before {
      position: absolute;
      background: rgba(255, 255, 255, 0.2);
      width: 100%;
      height: 100%;
      content: '';
      top: 0;
      left: 0;
      z-index: 3;
    }

  }

  &:hover {
    color: white;
    opacity: 0.8;
  }

  &.vertical {
    flex-direction: column;
    max-width: 250px;
  }

  &.horizontal {
    flex-direction: row;
    justify-content: center;
  }

  &.pink {
    background-image: linear-gradient(to left, #FF5FB5, #FFAFC7);
  }

  &.blue {
    background-image: linear-gradient(to left, #6975FF, #CEAFFF);
  }

  .img-container {
    z-index: 2;
    height: 150px;
    width: auto;

    img {
      object-fit: fill;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .btn-body {
    z-index: 2;

    margin: 10px 0;

    h5 {
      font-weight: 600;
      font-size: 2rem;
    }

    p {
      font-weight: 400;
    }

  }

}

.img-container-not-found {
  width: 50%;
  height: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }

}

.img-default {
  width: 200px;
  height: 200px;
  opacity: 0.5;

  img {
    object-position: center;
    object-fit: cover;
  }


}

.container-chat {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-active {
  border: 1px solid $primary;
}


@include media-breakpoint-down(md) {
  .gradient-button {
    flex-direction: column;
    padding: 21px 20px;
    border-radius: 12px;
  }
  .chat {
    @extend .bg-white-sh;
    flex-direction: column;
    margin-left: 0;
    margin-top: 1rem;

    .chat-container {
      height: 100%;
      overflow-y: scroll;
      display: flex;
      flex-direction: column-reverse;
    }

  }

}