.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    font-size: 20px;
    font-weight: 700;
  }

  svg {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .not-found {

    span {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    svg {
      width: 100%;
    }
  }
}