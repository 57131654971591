.organization-card {
  background: white;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  padding: 15px;
  margin: 0 0 12px 0;
  box-shadow: 0 0 10px rgba(170, 170, 170, 0.16);
  transition: 0.25s ease-in;
  align-items: center;

  .warn {
    color: #ff8800 !important;
    font-weight: 400 !important;
  }

  &:hover {
    transform: scale(1.05, 1.05);
  }

  .left-side {
    display: flex;
    flex-direction: column;

    h5 {
      color: black;
      font-weight: 400;
      font-size: 1rem;
    }

    p {
      color: $gray-1;
      font-weight: 300;
    }
  }

  .right-side {
    display: flex;
    align-items: center;

    .request {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 12px 15px;
      border-radius: 6px;
      background: #FFA9A9;
    }

    .expires {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 12px 15px;
      border-radius: 6px;
      background: #76BDFF;
    }

    .message-count {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 9px;
      border-radius: 6px;
      background: #100933;

      .count {
        width: 24px;
        height: 24px;
        background: #76BDFF;
        color: white;
        border-radius: 100%;
        text-align: center;
        margin-right: 10px;
      }


    }

    .message-void {
      padding: 9px;
      display: flex;
      align-items: center;
      content: 'asd';
    }

    p {
      font-weight: 500;
      color: $gray-1;
    }
  }
}


.sold-out-indicator {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #464646;
}

.sale-request-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  //flex: 0 1 auto;

  .year-selector {
    max-width: 400px;
    overflow-x: scroll;
    display: flex;
    background: white;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      background-color: $background;
      height: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b7;

    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b7;
      border-radius: 10px;
    }


    .item {
      border-radius: inherit;
      padding: 11px;
      margin-right: 10px;
      font-weight: 600;
      color: $gray-1;
      transition: 0.25s;
      cursor: pointer;

      &:hover {
        color: white;
        background: #76BDFF;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: white;
        background: #76BDFF;
      }
    }
  }

  .request-selector {
    display: flex;
    flex-direction: row;
    background: white;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 10px;
    overflow: auto;
    max-width: 1075px;

    .sold-out {
      background-color: #464646;
      color: white !important;
    }

    &::-webkit-scrollbar {
      background-color: $background;
      height: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b7;

    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b7;
      border-radius: 10px;
    }

    .item {
      clear: both;
      display: flex;
      flex: 0 0 auto;
      cursor: pointer;
      border-radius: inherit;
      font-weight: 600;
      padding: 11px;
      color: $gray-1;
      margin-right: 10px;
      transition: 0.25s;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background: $primary;
        color: white;
      }

      &:hover {
        background: $primary;
        color: white;
      }

    }


  }

}


@include media-breakpoint-down(md) {

  .sale-request-container {
    .year-selector {
      max-width: 300px;
    }

    .request-selector {

      &::-webkit-scrollbar {
        background-color: $background;
        height: 10px !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b7b7b7;

      }

      &::-webkit-scrollbar-thumb {
        background-color: #b7b7b7;
        border-radius: 10px;
      }


    }

  }

}










